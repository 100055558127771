<template>
  <a-layout class="layout">
    <!-- <a-layout-header class="header"> -->
    <!-- <top-title v-if="application.id"></top-title> -->
    <!-- </a-layout-header> -->
    <a-layout-content class="layout-content">
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from "vuex";

// import TopTitle from "@/views/common/TopTitle";

export default {
  // components: { TopTitle },
  computed: {
    ...mapState({
      application: (state) => {
        return state.application;
      },
    }),
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.layout {
  min-height: 100vh;

  &-header {
    background: #ffffff;
    height: 117px;
  }

  &-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>